@use "ds";
@use "tokens";
@use "typography";

.container {
  width: 100%;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 24px;

    &:not(:last-child) {
      border-bottom: 0;

      .body {
        border-bottom: 0.3px solid tokens.$color_border_surface;
      }
    }
  }
}

.body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
  align-items: center;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 16px 0;
    align-items: flex-start;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-columns: 250px minmax(220px, 304px) 130px;
    column-gap: 16px;
    align-items: flex-start;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: grid;
    grid-template-columns: minmax(0, 250px) minmax(0, 304px) 130px;
    column-gap: 16px;
  }
}

.title {
  @include typography.font_label_standard_medium;

  color: tokens.$color_text_primary;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 7px 0;
  }
}

.address {
  @include typography.font_label_small_regular;

  color: tokens.$color_text_secondary;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 8px 0;
  }
}

.partnerShip {
  @include typography.font_label_small_regular;

  display: flex;
  align-items: center;
  column-gap: 8px;
  color: tokens.$color_text_secondary;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 4px 0;
  }
}

.tooltipText {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: fit-content;
}

.tooltipLink {
  width: fit-content;
}
