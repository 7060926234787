@use "ds";
@use "mixins";
@use "tokens";
@use "typography";

.link {
  text-decoration: none;
  display: block;
  padding: 0 16px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    transition: background-color 0.3s ease;
    border: 0;
    padding: 0 24px;

    &:has(+ &:hover) {
      .inner {
        border-bottom-color: transparent;
        transition: 0.3s ease;
      }
    }

    &:not(:last-child) {
      border: 0;

      .inner {
        border-bottom: 0.3px solid tokens.$color_border_surface;
        transition: 0.3s ease;
      }
    }

    &:hover {
      background-color: tokens.$color_bg_control_primary_hover;

      &:not(:last-child) {
        .inner {
          border-bottom-color: transparent;
          transition: 0.3s ease;
        }
      }

      .avatar {
        [class*="root"] > [class*="inner"] {
          background: tokens.$color_bg_surface_primary;
          transition: 0.3s ease;
        }
      }
    }
  }

  .inner {
    display: grid;
    grid-template-columns: 68px 1fr;
    column-gap: 20px;
    align-items: center;
    padding: 16px 0;
    min-height: 99px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-template-columns: 56px 1fr;
      padding: 20px 0;
    }
  }

  .avatar {
    position: relative;
    width: 68px;
    height: 68px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      width: 58px;
      height: 58px;

      [class*="root"] > [class*="inner"] {
        transition: 0.3s ease;
      }
    }
  }

  .mainContentContainer {
    display: block;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: grid;
      grid-template-columns: 180px minmax(158px, 516px) 100px 116px;
      align-items: center;
      column-gap: 16px;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      grid-template-columns: minmax(110px, 180px) minmax(140px, 516px) 100px 116px;
    }
  }

  .agentInfo {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-column: 1;
    }
  }

  .title {
    display: flex;
    column-gap: 8px;
    color: tokens.$color_text_primary;
  }

  .titleText {
    @include mixins.box;

    color: tokens.$color_text_primary;
  }

  .titleIcon {
    background-color: tokens.$color_bg_surface_primary;
    border-radius: tokens.$radius_full;
    color: tokens.$color_text_success;
  }

  .secondaryText {
    color: tokens.$color_text_secondary;
    display: block;
  }

  .textBox {
    @include mixins.box;
  }

  .mobileOffersCount {
    display: block;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: none;
    }
  }

  .desktopOffersCount {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: block;
    }
  }

  .dealsCount {
    display: block;
  }

  .companyText {
    margin-top: 4px;
  }

  .registrationDate {
    color: tokens.$color_text_primary;
    margin-top: 4px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      color: tokens.$color_text_secondary;
    }
  }

  .rating {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    margin-top: 6px;
    color: tokens.$color_text_secondary;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-column: 2;
      grid-row: 1 / 3;
      align-items: flex-start;
      flex-direction: column;
      column-gap: 6px;
      margin: 0;
    }
  }

  .inWork {
    margin-top: 6px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      margin: 0;
      width: 116px;
    }
  }

  .inWorkPrimary {
    @include typography.font_label_small_regular;

    display: none;
    color: tokens.$color_text_secondary;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_body_standard_medium;

      color: tokens.$color_text_primary;
      display: block;
      padding-bottom: 2px;
    }
  }

  .complete {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: block;
      width: 100%;
      color: tokens.$color_text_secondary;
    }
  }

  .completePrimary {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_body_standard_medium;

      display: block;
      color: tokens.$color_text_primary;
    }
  }

  .reviews {
    @include mixins.box(1);

    color: tokens.$color_text_secondary;
  }
}
