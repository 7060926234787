@use "ds";
@use "tokens";

.offersListSkeletonHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin-bottom: 12px;
  }

  .item {
    border-radius: tokens.$radius_large;
  }
}
