@use "ds";
@use "tokens";

.reviewsCardSkeleton {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 0.3px solid tokens.$color_bg_control_primary_selected;
    }
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr 96px;
  row-gap: 4px;
}

.title {
  max-width: 30%;
}

.subtitle {
  max-width: 60%;
  grid-column: 1;
}

.rating {
  grid-row: 1;
  grid-column: 2;
}

.footer {
  grid-column: 1/-1;
}
