@use "ds";
@use "mixins";
@use "tokens";

.container {
  padding-left: 20px;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding-left: 32px;
  }
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 12px;

  .titleText {
    @include mixins.box(1);
  }

  .subTitle {
    color: tokens.$color_text_secondary;
  }
}

.avatar {
  grid-column: 1;
  grid-row: 1 / -1;
}

.title {
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  align-items: center;
}

.replyToReviewContainer {
  display: flex;
  column-gap: 12px;
  grid-column: 1 / -1;
  justify-content: space-between;
}

.dropdownWrapper {
  position: relative;
}
