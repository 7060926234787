@use "tokens";

.layoutAvatar {
  &.contain {
    [class*="object-fit"] {
      object-fit: contain;
    }
  }

  &.hasError [class*="error"] {
    background: tokens.$color_bg_accent_neutral_default;

    [class*="icon"] {
      display: none;
    }
  }
}
