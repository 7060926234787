@use "tokens";
@use "mixins";

.agentsCardMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  background-color: tokens.$color_bg_surface_primary;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  &.lastInCurrentRegion {
    border-bottom-color: transparent;
    transition: 0.3s ease;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .inner {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .avatar {
    position: relative;
    width: 68px;
    height: 68px;

    [class*="avtr-inner"] {
      background-color: tokens.$color_bg_page;
    }
  }

  .titleIcon {
    background-color: tokens.$color_bg_surface_primary;
    border-radius: tokens.$radius_full;
    color: tokens.$color_text_success;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
  }

  .header {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 8px;
  }

  .title {
    @include mixins.box(2);
  }

  .companyText {
    @include mixins.box(2);
  }

  .rating {
    height: 20px;
    flex-shrink: 0;
  }

  .text {
    color: tokens.$color_text_secondary;
  }

  .region {
    display: flex;
    column-gap: 4px;
  }

  .features {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr;

    &.isOneElement {
      grid-template-columns: 100%;
    }
  }

  .feature {
    position: relative;
    z-index: 2;
    grid-row: 1;
  }
}
