@use "ds";
@use "mixins";
@use "tokens";
@use "typography";

.offersListItem {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 167.5px;
  text-decoration: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 16px;
    width: 237px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    @include typography.font_heading_xsmall;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
  }

  .picture {
    border-radius: tokens.$radius_large;
    overflow: hidden;

    [class*="icon-root-"] {
      display: none;
    }

    img,
    picture {
      border-radius: tokens.$radius_large;
      width: 100%;
      height: 128px;

      @media #{ds.$dc-mq-tablet-portrait-and-more} {
        height: 213px;
      }
    }
  }

  .price {
    @include typography.font_label_large_medium;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;
    }
  }

  .specifications {
    display: flex;
    column-gap: 8px;

    .specificationsItem {
      @media #{ds.$dc-mq-tablet-portrait-and-more} {
        @include typography.font_label_standard_regular;
      }
    }
  }

  .address {
    @include mixins.box(2);

    color: tokens.$color_text_secondary;
    text-align: left;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_label_standard_regular;

      color: tokens.$color_text_secondary;
    }
  }
}
