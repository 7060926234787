@use "tokens";

.reviewSubTitle {
  .link {
    font: inherit;
    color: tokens.$color_component_link_primary_text_default;
    text-decoration: none;

    &:hover {
      color: tokens.$color_component_link_primary_hover;
    }
  }
}
