@use "mixins";
@use "ds";

.offersListSkeletonContent {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-top: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    column-gap: 16px;
  }
}
