@use "tokens";

.offersListSkeletonItem {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;

  .poster {
    margin-bottom: 8px;
    border-radius: tokens.$radius_large;
  }

  .text {
    border-radius: tokens.$radius_large;
  }
}
