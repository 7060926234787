@use "tokens";
@use "ds";

.container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.poster {
  border-radius: tokens.$radius_large;
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-columns: 200px 150px auto;
    column-gap: 4px;
  }
}

.characteristics {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    order: -1;
  }
}

.item {
  border-radius: tokens.$radius_large;
}
