@use "ds";
@use "tokens";
@use "mixins";
@use "typography";

.agentsCardDesktop {
  padding: 20px 24px 0;
  position: relative;
  background-color: tokens.$color_bg_surface_primary;
  transition: 0.3s ease;

  &:has(+ &:hover) {
    .inner {
      border-bottom-color: transparent;
      transition: 0.3s ease;
    }
  }

  &:hover {
    cursor: pointer;
    outline: none;
    background-color: tokens.$color_bg_control_primary_hover;

    .avatar {
      [class*="root"] > [class*="inner"] {
        background: tokens.$color_bg_surface_primary;
        transition: 0.3s ease;
      }
    }

    &:not(:last-child) {
      .inner {
        border-bottom-color: transparent;
        transition: 0.3s ease;
      }
    }

    .features {
      button:not([class*="btn-primary"]) {
        background: tokens.$color_bg_surface_primary;
        transition: 0.3s ease;
      }
    }
  }

  &:not(:last-child) {
    .inner {
      border-bottom: 0.3px solid tokens.$color_border_surface;
      transition: 0.3s ease;
    }
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
  }

  .inner {
    padding-bottom: 24px;
    display: grid;
    column-gap: 12px;
    align-items: center;
    grid-template-columns: minmax(247px, 358px) minmax(130px, 180px) minmax(52px, 106px) minmax(85px, 106px) 158px;

    &.isDistrict {
      grid-template-columns: minmax(247px, 376px) minmax(160px, 296px) minmax(85px, 106px) 158px;
    }

    &.lastInCurrentRegion {
      border-bottom-color: transparent;
      transition: 0.3s ease;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      column-gap: 32px;
      grid-template-columns: 280px minmax(268px, 452px) 50px 90px 158px;

      &.isDistrict {
        grid-template-columns: 280px minmax(268px, 506px) 90px 158px;
      }
    }
  }

  .personalData {
    display: flex;
    align-items: center;
    column-gap: 32px;
    width: 100%;
  }

  .avatar {
    position: relative;
    width: 68px;
    height: 68px;

    [class*="root"] > [class*="inner"] {
      transition: 0.3s ease;
    }

    [class*="avtr-inner"] {
      background-color: tokens.$color_bg_page;
    }
  }

  .titleIcon {
    background-color: tokens.$color_bg_surface_primary;
    border-radius: tokens.$radius_full;
    color: tokens.$color_text_success;
  }

  .secondaryText {
    @include mixins.box(2);
    @include typography.font_label_small_regular;

    color: tokens.$color_text_secondary;
  }

  .info {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;
  }

  .title {
    @include mixins.box;
  }

  .rating {
    height: 18px;
  }

  .region {
    display: flex;
    column-gap: 4px;
  }

  .review {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .reviewHeader {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .feedbackDescription {
    display: none;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      @include mixins.box(2);

      color: tokens.$color_text_primary;
    }
  }

  .strongText {
    text-align: center;
  }

  .features {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &.chats-accent {
      flex-direction: column-reverse;
    }
  }

  .feature {
    position: relative;
    z-index: 2;

    &:hover {
      button:not([class*="btn-primary"]) {
        background: tokens.$color_bg_accent_neutral_hover;
      }
    }
  }
}
