@use "ds";
@use "mixins";

.offersList {
  grid-column: 1;

  .list {
    @include mixins.horizontal-scroll-hidden;

    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    margin-top: 12px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      column-gap: 16px;
      row-gap: 16px;
    }
  }

  .showMore {
    margin-top: 16px;
  }
}
