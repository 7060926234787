@use "ds";
@use "tokens";

.container {
  grid-column: 1;
}

.list {
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  row-gap: 8px;
  margin-top: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    column-gap: 16px;
    row-gap: 16px;
  }
}

.showMore {
  margin-top: 16px;
}
