@use "ds";
@use "tokens";
@use "mixins";

.container {
  @include mixins.horizontal-scroll-hidden;

  padding-left: 16px;
  padding-right: 16px;
  background-color: tokens.$color_bg_surface_primary;
  border-radius: tokens.$radius_none;
  width: 100%;
  max-width: 1180px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 32px;
    border-radius: tokens.$radius_xlarge tokens.$radius_xlarge tokens.$radius_none tokens.$radius_none;
    margin: 20px auto 0;
  }
}

.tabs {
  width: fit-content;
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0;
  }
}

.links {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}
