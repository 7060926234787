@use "ds";
@use "mixins";
@use "tokens";

.container {
  @include mixins.default-layout;

  background: tokens.$color_bg_surface_primary;
  display: flex;
  flex-direction: column;
  border-radius: tokens.$radius_xlarge;
  margin: 12px 0;
  justify-content: center;
  align-items: center;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 20px auto;
  }
}
