@use "ds";
@use "tokens";

.page {
  width: 100%;
  max-width: 1180px;
  height: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.isWebView {
    flex: 1 0 auto;
    border-radius: tokens.$radius_none;
    margin: 0;

    div:first-child {
      margin: auto 0;
    }
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 20px auto;
  }

  @media #{ds.$dc-mq-mobile-and-less} {
    & > div {
      border-top-right-radius: tokens.$radius_none;
      border-top-left-radius: tokens.$radius_none;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
