@use "mixins";
@use "ds";

.container {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 24px;
  margin-top: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    column-gap: 16px;
  }
}
