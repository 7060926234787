@use "mixins";
@use "tokens";
@use "ds";

.container {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;
  align-items: center;

  .description {
    grid-column: 1 / -1;

    &.isPreview {
      @include mixins.box(3);

      width: 100%;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 12px;
    row-gap: 4px;
  }

  .avatar {
    align-self: flex-start;
  }

  .title {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    align-items: center;
  }

  .titleText {
    @include mixins.box(1);

    font-weight: 500;
  }

  .subTitle {
    color: tokens.$color_text_secondary;
    grid-row: 2;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: 8px;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      display: flex;
    }
  }

  .reviewButtons {
    display: grid;
    grid-column: 1 / -1;
    width: 100%;
    column-gap: 8px;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      width: fit-content;
    }
  }

  .answerToReviewButton {
    grid-column: 1 / -1;

    button {
      width: 100%;

      @media #{ds.$dc-mq-tablet-landscape-and-more} {
        width: fit-content;
      }
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    grid-column: 1 / -1;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      grid-column: 2;
    }
  }
}
