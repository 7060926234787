@use "mixins";
@use "tokens";
@use "typography";

.container {
  display: inline-flex;
  align-items: center;

  &.primary {
    column-gap: 8px;
  }

  &.secondary {
    column-gap: 0;
  }
}

.starsContainer {
  position: relative;
  flex-shrink: 0;
  height: 16px;
}

.defaultStars {
  display: inline-block;
  white-space: nowrap;
  height: 100%;
}

.activeStars {
  display: inline-block;
  column-gap: 4px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  height: 100%;
}

.star {
  width: 16px;
  height: 16px;
  color: tokens.$color_bg_placeholder;

  &:not(:last-child) {
    margin-right: 4px;
  }
}

.activeStar {
  color: tokens.$color_bg_accent_warning_contrast;
}

.grade {
  line-height: 100%;
  color: tokens.$color_text_primary;

  &.sm {
    @include typography.font_body_standard_medium;

    line-height: 100%;
  }

  &.md {
    @include typography.font_heading_xsmall;

    line-height: 100%;
    padding-top: 2px;
  }
}
