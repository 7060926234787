@use "ds";
@use "tokens";
@use "typography";

.offersListHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin-bottom: 12px;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }

  .count {
    @include typography.font_label_standard_medium;

    color: tokens.$color_text_secondary;
  }
}
