@use "ds";
@use "tokens";
@use "typography";

@include ds.dc-font-sb-sans;

body {
  @include typography.font_body_standard_regular;

  background-color: tokens.$color_bg_page;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; //stylelint-disable-line
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

* {
  box-sizing: border-box;
}

p,
button,
ul,
ol,
h1,
h2 {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  width: auto;
  background: transparent;
  color: inherit;
  font-family: tokens.$color_text_primary;
  outline: none;
  cursor: pointer;
}

ul,
ol {
  text-indent: 0;
  list-style-type: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

:global(#app) {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

[class*="btm-sht-root"] {
  overflow: hidden;

  [class*="btm-sht-content"] {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
