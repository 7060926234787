@use "tokens";
@use "typography";

.description {
  @include typography.font_body_standard_regular;

  display: inline;
}

.button {
  @include typography.font_body_standard_medium;

  color: tokens.$color_text_info;
}
