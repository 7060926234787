@use "tokens";
@use "mixins";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .inner {
    display: flex;
    column-gap: 20px;
  }

  .avatar {
    width: 56px;
    height: 56px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
  }

  .header {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 8px;
  }

  .title {
    @include mixins.box(2);
  }

  .rating {
    height: 20px;
    flex-shrink: 0;
  }

  .text {
    color: tokens.$color_text_secondary;
  }

  .button {
    position: relative;
    z-index: 2;
  }
}
