@use "ds";
@use "tokens";
@use "typography";

.item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  column-gap: 20px;

  &:not(:last-child) {
    border-bottom: 0.5px solid tokens.$color_border_surface;
  }

  .description {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: grid;
      grid-template-columns: 200px 150px auto;
      column-gap: 4px;
    }
  }

  .price {
    @include typography.font_label_standard_semibold;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_label_standard_regular;
    }
  }

  .characteristics {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      order: -1;
    }
  }

  .address {
    color: tokens.$color_text_secondary;
    text-align: left;
  }
}
